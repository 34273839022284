<template>
    <div class="PageHeader_Wrapper">
        <div class="Header_Container">
            <div class="logo">
                <router-link to="/">
                    <img src="../assets/images/logo-w.png">
                </router-link>
            </div>
    
            <img src="../assets/images/Mobile.png" @click="showSideMenu">
            
    
            <div class="SideMenu_Wrapper" v-if="isSideMenuVisible">
                <div class="SideMenu_Container">
                    <div class="Header">
                        <div class="logo">
                            <router-link to="/">
                                <img src="../assets/images/logo-w.png">
                            </router-link>
                        </div>
        
                        <p @click="hideSideMenu">X</p>
                    </div>
                    
                
                    <ul class="Menu_Container MobileMenu">
                        <li @click="clickAbout">
                            <a href="" :class="{active: isMenuActive}">About</a>
                            ▼
                        </li>
                        <li @click="clickProduct">
                            <a href="" :class="{active: isMenuActive}">Product</a>
                            ▼
                            
                        </li>
                        <li>
                            <a href="/project">Project</a>
                            
                        </li>
                        <li @click="clickPeople">
                            <a href="" :class="{active: isPeopleActive}">People</a>
                            ▼
                        </li> 
                        <li @click="clickRecruit">
                            <a href="" :class="{active: isRecruitActive}">Recruit</a>
                            ▼
                        </li>
                    </ul>
                
                    <ul class="About MobileSubMenu" v-if="showAbout">
                            <a href="/about/history">
                                <li>회사연혁</li>
                            </a>

                            <a href="/about/vision">
                                <li>Vision</li>
                            </a>

                            <a href="/about/ci">
                                <li>CI소개</li>
                            </a>

                            <a href="/about/contact">
                                <li>오시는 길</li>
                            </a>
                    </ul>

                    <ul class="Product MobileSubMenu" v-if="showProduct">
                        <a href="/bimil">
                            <li>BIMIL</li>
                        </a>

                        <a href="/product">
                            <li>제품 소개</li>
                        </a>
                    </ul>
            
    
                    <ul class="People MobileSubMenu" v-if="showPeople">
                        <a href="/people/talent">
                            <li>인재상</li>
                        </a>

                        <a href="/people/department">
                            <li>인사제도</li>
                        </a>

                        <a href="/people/members">
                            <li>Peers</li>
                        </a>
                    </ul>
            
    
                    <ul class="Recruit MobileSubMenu" v-if="showRecruit">
                        <a href="/recruit/process">
                            <li>채용프로세스</li>
                        </a>

                        <a href="/recruit/list">
                            <li>채용정보</li>
                        </a>
                    </ul> 
                </div>
            </div>
        </div>
        
        
    </div>
</template>


<style scoped>
.PageHeader_Wrapper {
    position: absolute;
    top:20px;
    left: 0;
    right: 0;
    width: 100%;
    height: 80px;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 2px solid rgba(255,255,255,.25);
    z-index: 100;
    justify-content: center;
    transition: top 0.3s ease;
}
.Header_Container {
    display: flex;
    flex-direction: row;
    align-items: center;
    max-width: 354px;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}
.logo {
    grid-column:2;
}
.logo a img {
    height:60px;
}
.menu {
    grid-column: 3;
    height:24px;
    justify-self: end;
}
.Menu_Container p {
    position: inherit;
    top:30px;
    right:30px;
    font-size:1.5rem;
    font-weight:bold;
    color:#fff;
}
.Menu_Container ul {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: flex-start;
}
.Menu_Container li {
    border-bottom: 1px solid rgba(255,255,255,.4);
    width: 100%;
    height: 80px;
    display: flex;
    flex-direction: row;
    justify-content:space-between;
    align-items: center;
    color: #fff;
    padding-right: 44px;
}
.Menu_Container li.active {
    background: #fff;
}
.Menu_Container li a {
    margin-left:44px;
    text-decoration: none;
    color:#fff;
    font-size:1.2rem;
    font-weight:bold;
    font-family: "Pretendard, sans-serif";
    list-style: none;
    letter-spacing: .5px;
}
.SideMenu_Wrapper {
    position: absolute;
    top:-20px;
    left:0;
    width:100%;
    height:100vh;
    display: flex;
    flex-direction: column;
    background: #021623;
}
.Header {
    position: relative;
    top: 0px;
    left:0;
    width: 100%;
    height: 80px;
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.Header .logo {
    padding-left: 20px;
}
.Header p {
    font-size: 2rem;
    color:#fff;
    padding-right:20px;
}
.MobileMenu {
    margin-top:60px;
    display: flex;
    flex-direction: column;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    background: #021623;
    list-style: none;
}


.MobileSubMenu {
    display: flex;
    flex-direction: column;
    width: 100%; /* Full width */
    background: #fff;
    list-style: none;
    padding: 0; 
    z-index: 9999;
}

.MobileSubMenu li {
    line-height: 40px;
    color: #787878;
    font-size: 0.8rem;
    font-weight: 300;
    border-bottom: 1px solid #ccc;
    padding-left: 44px;
    cursor: pointer;
    height: 40px; /* Make the list items full height */
}
.MobileSubMenu li a {
    color:#787878
}
.MobileSubMenu li:hover {
    background: #ccc;
    color: #787878;
}
  
.About {
    position: absolute;
    top:140px;
    display: flex;
    flex-direction: column;
    line-height: 40px;
    color: #787878;
    font-size: .8rem;
    font-weight: 300;
    margin-top:100px;
    background: #fff;
    z-index: 101;
    width: 100vw;
    list-style: none;
}
.About li,
.People li,
.Recruit li {
    border-bottom:1px solid #ccc;
    padding-left: 44px;
    background:#ececec;
    height:40px;
}
.About li:hover,
.People li:hover,
.Recruit li:hover {
    background:#ccc;
    color:#787878;
}
.Product {
    position: absolute;
    top:-80px;
    display: flex;
    flex-direction: column;
    line-height: 40px;
    color: #787878;
    font-size: .8rem;
    font-weight: 300;
    margin-top: 400px;
    background: #fff;
    z-index: 101;
    width: 100vw;
    list-style: none;
}
.People {
    position: absolute;
    top:80px;
    display: flex;
    flex-direction: column;
    line-height: 40px;
    color: #787878;
    font-size: .8rem;
    font-weight: 300;
    margin-top: 400px;
    background: #fff;
    z-index: 101;
    width: 100vw;
    list-style: none;
}
.Recruit {
    position: absolute;
    top:60px;
    display: flex;
    flex-direction: column;
    line-height: 40px;
    color: #787878;
    font-size: .8rem;
    font-weight: 300;
    margin-top: 500px;
    background: #fff;
    z-index: 101;
    width: 100vw;
    list-style: none;
}
.About li a,
.Recruit li a,
.People li a {
    cursor: pointer;
}

</style>

<script>
export default {
    data() {    
        return {
            isSideMenuVisible:false,
            showAbout:false,
            showPeople:false,
            showRecruit:false,
            showProduct:false,
            section3Observer:null,
            isAnimationStarted:false,
            isAboutActive:false,
            isPeopleActive:false,
            isRecruitActive:false,
            isProductActive:false
        }
    },

    methods: {
        showSideMenu() {
            this.isSideMenuVisible = true;
        },
        hideSideMenu() {
            this.isSideMenuVisible = false;
        },
        
        clickSideMenu() {
            this.showSideMenu = !this.showSideMenu
        },
        clickAbout() {
            this.showAbout = !this.showAbout;
            this.isAboutActive = !this.isAboutActive
        },
        clickProduct() {
            this.showProduct = !this.showProduct;
            this.isProductActive = !this.isProductActive
        },
        clickPeople() {
            this.showPeople = !this.showPeople;
            this.isPeopleActive = !this.isPeopleActive
        },
        clickRecruit() {
            this.showRecruit = !this.showRecruit;
            this.isRecruitActive = !this.isRecruitActive
        },
        closeMenu() {
            this.showSideMenu = !this.showSideMenu
        },
    },
};
</script>





