<template>
    <div id="RecruitListDetail_main">
        <Header />
        <div class="RecruitListDetail_Div" v-if="jsonData && Object.keys(jsonData).length > 0">
            <div class="RecruitListDetail_Container">
                <div class="RecruitListDetail_Title">
                    <h2>{{  jsonData.name }}</h2>
                    <p><strong>{{ jsonData.career }}, {{ jsonData.ability }}</strong></p>
                    <p>
                        BIMPeers는 2008년에 설립된 전문 소프트웨어<br>개발 업체 입니다. 15년간 쌓은 독보적인 기술력으로<br>
                        국내 대형 건설사 및 글로벌 기업과 다수의 프로젝트를<br>진행하고 있으며, 체계적이고 효율적인 건축, 건설분야<br>
                        정보화 솔루션 제공을 위해 노력하고 있습니다.<br>빔피어스와 함께 할 열정 있는 인재를 기다립니다.
                    </p>
                </div>
    
                <div class="List_Div" >
                    <div class="List_table">
                        <h2>공통 자격요건 & 우대사항</h2>
                        <ul class="ListSection">
                            <li class="texts">· 학력 : {{ jsonData.ability}}</li>
                            <li class="texts">· 전공 : {{ jsonData.major }}</li>
                            <li class="texts">· 자격증 : {{ jsonData.spec1 }}</li>
                        </ul>
                    </div>
    
                    <div class="List_table">
                        <h2>신입 자격요건</h2>
                        <ul class="ListSection">
                            <li class="texts">· {{ jsonData.ncareer1}}</li>
                            <li class="texts"> {{ jsonData.ncareer2}}</li>
                            <li class="texts"> {{ jsonData.ncareer3}}</li>
                            <li class="texts"> {{ jsonData.ncareer4}}</li>
                        </ul>
                    </div>
    
                    <div class="List_table">
                        <h2>경력 자격요건</h2>
                        <ul class="ListSection">
                            <li class="texts">· {{ jsonData.ecareer1}}</li>
                            <li class="texts">· {{ jsonData.ecareer2}}</li>
                            <li class="texts">· {{ jsonData.ecareer3}}</li>
                            <li class="texts">· {{ jsonData.ecareer4}}</li>
                            <li class="texts">· {{ jsonData.ecareer5}}</li>
                        </ul>
                    </div>
    
                    <div class="List_table">
                        <h2>근무조건</h2>
                        <ul class="ListSection">
                            <li class="texts">· {{ jsonData.work1}}</li>
                            <li class="texts">· {{ jsonData.work2}}</li>
                            <li class="texts">· {{ jsonData.work3}}</li>
                        </ul>
                    </div>
    
                    <div class="List_table">
                        <h2>급여조건</h2>
                        <ul class="ListSection">
                            <li class="texts">· {{  jsonData. pay1}}</li>
                            <li class="texts">· {{  jsonData. pay2}}</li>
                        </ul>
                    </div>
    
                    <div class="List_table">
                        <h2>복리후생</h2>
                        <ul class="ListSection">
                            <li class="texts">· {{  jsonData. welfare1}}</li>
                            <li class="texts">· {{  jsonData. welfare2}}</li>
                            <li class="texts">· {{  jsonData. welfare3}}</li>
                            <li class="texts">· {{  jsonData. welfare4}}</li>
                            <li class="texts">· {{  jsonData. welfare5}}</li>
                        </ul>
                    </div>
    
                    <div class="List_table_icon">
                        <h2>채용절차</h2>
                        <img src="../../assets//images/recruit/process.png" alt="">
                        <!-- <ul class="ListSection">
                            <li class="icons"><img src="../assets/images/icons/category_list_icon_1.png" alt="Alternate Text" /><p>서류전형</p></li>
                            <li class="icons"><img src="../assets/images/icons/category_list_icon_2.png" alt="Alternate Text" /><p>면접전형</p></li>
                            <li class="icons"><img src="../assets/images/icons/category_list_icon_3.png" alt="Alternate Text" /><p>입사협의</p></li>
                            <li class="icons"><img src="../assets/images/icons/category_list_icon_4.png" alt="Alternate Text" /><p>최종결과</p></li>
                        </ul> -->
                    </div>
    
                    <!-- <div class="List_table">
                        <h2>접수기간 및 방법</h2>
                        <ul class="ListSection">
                            <li class="texts">·접수기간 : 2022년 8월 2일 (화) ~ 2022년 8월 15일 (월)</li>
                            <li class="texts">·접수방법 : 잡코리아 입사지원 / BIMPeers 홈페이지 지원</li>
                            <li class="texts">·이력서 양식 : 온라인 이력서 </li>
                            <li class="texts_small">＊ 입사지원 서류에 허위사실이 발견될 경우, 채용 확정 이후라도 채용이 취소될 수 있습니다.</li>
                        </ul>
                    </div> -->
    
                    <div class="buttonDiv">
                        <div class="listBtn">
                            <router-link to='/recruit/list'>목록 보기</router-link>
                        </div>
    
                        <div class="goBtn">
                            <router-link to='/recruit/apply'>지원하기</router-link>
                        </div>
                    </div> 
                </div>

            </div>

        </div>

        <div v-else>
        </div>
        <Footer />
    </div>

</template>

<style scoped>
    @import "../../assets//css/recruitListDetail.css";

</style>

<script>
import jsonData from "../../assets/tables/recruitList.json";
import Header from "../../components/PageHeader.vue"
import Footer from "../../components/PageFooter.vue"

export default { 
    name:'App',
    components: {
        Header,
        Footer
    },
    data() {
        return {
            jsonData: null,
            recruitName:""
        }
    },
    // created() {
    //     console.log("jsonData:", jsonData); // Check the content of jsonData

    //     const recruitName = this.$route.params.name;
    //     console.log("Recruit Name:", recruitName);
    // },

    mounted() {
        this.recruitName = this.$route.params.name;
    this.jsonData = jsonData.find(item => item.name === this.recruitName);
    }


}
</script>
