<template>
    <div id="bimilMobile_Main">
        <div id="bimilMobile_Header">
            <div class="pageHeader_Container">
                <Header />
            </div>
            
        </div>
        
        <div class="bimilMobileDiv">
            <div class="bimilMobile_Container">
                <section class="bimilMobile_mainSection">
                    <div class="mainSection_Container">
                        <div class="bimil_txtContainer">
                            <div>
                                <img src="../assets/images/icons/bimil.png" alt="">
                                <h2>BIMIL</h2>
                            </div>
                            <p>
                                BIMIL (BIM Integrated Lab) is a platform for Autodesk Revit® users,<br>
                                enabling them to select and download add-ins to enhance their workflows.<br><br>
        
                                It offers tools for better documentation and design, allowing architects,
                                engineers, and designers to customize their Revit experience<br>
                                for improved efficiency and collaboration.
                            </p>
                            <a :href="fileUrl" download="BIMIL-1.0.6.2.exe" class="download" @click="downloadFile">Download<br>
                                <span>(Version 1.0.6.2)</span>
                            </a>
                            <!-- <span class="ps">※ Until May 16th, installation can be accessed via an external link on the Autodesk website.</span> -->
                            <!-- <span class="ps">※ Until May 16th, installation can be accessed via an external link on the Autodesk website.</span> -->
                            <p class="essential">
                                ※ 일부 회사에서는 보안 프로그램으로 인해 서버 통신이 차단될 수 있습니다.<br>
                                &nbsp;&nbsp;&nbsp;이로 인해 인증 코드를 비롯한 실행에 문제가 발생할 수 있으니, 필요한 경우 관련 부서에<br>&nbsp;&nbsp;&nbsp;보안 해제를 요청하시기 바랍니다.
                            </p>
                        </div>
                        <img src="../assets/images/bimil_edited.png" alt="" class="bimilmain">
                    </div>
                </section>

                <section class="bimil_mediaSection">
                    <div>
                        <h2>Add-in Download Guide</h2>
                        <p>From BIMIL installation to add-in downloads, watch this video for a simple step-by-step guide.</p>
                    </div>
    
                    <div class="mediaFrame">
                        <iframe width="360" height="200" src="https://www.youtube.com/embed/2Zc_S-SOhcw?si=KCJwFVEI2xbmROf1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                    </div>
                </section> 

                <section class="bimil_contentSection">
                    <div class="bimil_contentTit">
                        <h2>Enhance Your Revit Workflow with BIMIL Add-ins</h2>
                        <p>BIMIL offers a variety of add-ins to improve workflows and increase efficiency for Revit users.<br>
                            Discover add-ins that can be used in various areas, including documentation, design, and collaboration.</p>
                    </div>
    
                    <div class="bimil_content_box">
                        <div>
                            <div class="img_Wrapper">
                                <img src="../assets/images/icons/GridtoCrop_icon64.png" alt="">
                            </div>
                            
                            <h4>Grid to Crop</h4>
                            <p>Align Grids and Levels with Crop Region boundaries</p>
                        </div>
                        <div>
                            <div class="img_Wrapper">
                                <img src="../assets/images/icons/AutoDimension_icon64.png" alt="">
                            </div>
                            <h4>Auto Dimension</h4>
                            <p>Automatically generate dimension lines for Grids and Levels</p>
                        </div>
                        <div>
                            <div class="img_Wrapper">
                                <img src="../assets/images/icons/ExcelLink_icon64.png" alt="">
                            </div>
                            <h4>Excel Link</h4>
                            <p>Upload and insert Excel data directly into Revit views</p>
                        </div>
                        <div>
                            <div class="img_Wrapper">
                                <img src="../assets/images/icons/AutoJoin_icon64.png" alt="">
                            </div>
                            <h4>Auto Join</h4>
                            <p>Customizable joining of geometries by category preferences</p>
                        </div>
                        <div>
                            <div class="img_Wrapper">
                                <img src="../assets/images/icons/AutoMaterialTag_icon64.png" alt="">
                            </div>
                            <h4>Auto Material Tag</h4>
                            <p>Two-click material tag creation for layered objects</p>
                        </div>
                        <div>
                            <div class="cmDate">
                                <span class="cmDate">May 2024</span>
                            </div>
                            <div class="img_Wrapper">
                                <img src="../assets/images/icons/comingSoon.png" alt="">
                            </div>
                            <h4>Coming Soon</h4>
                            <p>New tools added monthly<br>to boost your Revit workflow</p>
                        </div>
                        <!-- <div>
                            <div class="img_Wrapper">
                                <img src="../assets/images/icons/comingSoon.png" alt="">
                            </div>
                            <h4>Coming Soon</h4>
                            <p>New add-ins released monthly to boost your Revit workflow</p>
                        </div> -->
                    </div>
                </section> 

                <section class="bimil_itemSection">
                    <div class="bimil_itemContainer">
                        <div class="mediaItem">
                            <iframe width="350" height="200" src="https://www.youtube.com/embed/lIYa6BWZmAQ?si=5kVVqbJXMr7_fxbf" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                        </div>
                        <div class="bimil_item_info">
                            <div class="item_txt">
                                <div>
                                    <img src="../assets/images/icons/GridtoCrop_icon64.png" alt="">
                                </div>
                                <h4>Grid to Crop</h4>
                            </div>
                            <p>Align Grids and Levels with Crop Region boundaries</p>
    
                            <ul>
                                <li>One-click adjustment of spacing and positioning</li>
                                <li>Customizable spacing between Grids/Levels and Crop Regions</li>
                                <li>Operates only within the current view, without affecting others</li>
                            </ul>
                        </div>
                    </div>

                    <div class="bimil_itemContainer">
                        <div class="mediaItem">
                            <iframe width="350" height="200" src="https://www.youtube.com/embed/JhtXOX2orlA?si=KWT27CNoy3HKOaqW" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                        </div>
                        <div class="bimil_item_info">
                            <div class="item_txt">
                                <div>
                                    <img src="../assets/images/icons/AutoDimension_icon64.png" alt="">
                                </div>
                                <h4>Auto Dimension</h4>
                            </div>
                            <p>Automatically generate dimension lines for Grids and Levels</p>
    
                            <ul>
                                <li>One-click creation of dimensions for visible Grids and Levels</li>
                                <li>Adjustable dimension line spacing for tailored documentation</li>
                                <li>Selection of dimension line types to fit project specifications</li>
                            </ul>
                        </div>
                    </div>

                    <div class="bimil_itemContainer">
                        <div class="mediaItem">
                            <iframe width="350" height="200" src="https://www.youtube.com/embed/Jp7pwaUHRcQ?si=s_W06Bk1YIxCWFo8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                        </div>
                        <div class="bimil_item_info">
                            <div class="item_txt">
                                <div>
                                    <img src="../assets/images/icons/ExcelLink_icon64.png" alt="">
                                </div>
                                <h4>Excel Link</h4>
                            </div>
                            <p>Upload and insert Excel data directly into Revit views</p>
    
                            <ul>
                                <li>Seamless updates to Revit from modified Excel files</li>
                                <li>Clipboard content conversion into Revit tables
                                </li>
                                <li>Flexible formatting for Legends, Drafting Views, or Schedules
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div class="bimil_itemContainer">
                        <div class="mediaItem">
                            <iframe width="350" height="200" src="https://www.youtube.com/embed/AcSQ5MnXtFg?si=THdSrXwh0Q_dLGkC" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                        </div>
                        <div class="bimil_item_info">
                            <div class="item_txt">
                                <div>
                                    <img src="../assets/images/icons/AutoJoin_icon64.png" alt="">
                                </div>
                                <h4>Auto Join</h4>
                            </div>
                            <p>Customizable joining of geometries by category preferences
                            </p>
    
                            <ul>
                                <li>Dynamic priority setting for flexible modeling control</li>
                                <li>Wide application scope: all objects, visible objects, or selected objects
                                </li>
                                <li>Efficient management of join and unjoin operations</li>
                            </ul>
                        </div>
                    </div>

                    <div class="bimil_itemContainer">
                        <div class="mediaItem">
                            <iframe width="350" height="200" src="https://www.youtube.com/embed/-O9Jy083W2k?si=JerPEPP9rWz9STgp" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                        </div>
                        <div class="bimil_item_info">
                            <div class="item_txt">
                                <div>
                                    <img src="../assets/images/icons/AutoMaterialTag_icon64.png" alt="">
                                </div>
                                <h4>Auto Material Tag</h4>
                            </div>
                            <p>Two-click material tag creation for layered objects
                            </p>
    
                            <ul>
                                <li>Customizable display options for material type and thickness
                                </li>
                                <li>Dynamic tag updating to reflect changes in material layers
                                </li>
                                <li>Manual adjustment of tag visibility and orientation
                                </li>
                            </ul>
                        </div>
                    </div>
                </section>
            </div>
        </div>
        <Footer />
    </div>
</template>

<style scoped>
@import url('../assets/css/bimil.css');
#bimilMobile_Header {
    position: absolute;
    top:0;
    left: 0;
    right: 0;
    width: 100%;
    height: 80px;
    display: flex;
    flex-direction: row;
    align-items: center;
    z-index: 100;
    justify-content: center;
    transition: top 0.3s ease;
}
.pageHeader_Container {
    display:flex;
    flex-direction: row;
    max-width: 354px;
    width: 100%;
    justify-content: space-between;
    align-content: center;
}
.Footer_Wrapper {
    position: relative;
}
</style>

<script>
import Header from "../components/PageHeader.vue";
import Footer from "../components/PageFooter.vue";
export default {
    components: {
        Header,
        Footer
    },
    data() {
        return {
            fileUrl: 'https://www.bimpeers.com/release/BIMIL-1.0.6.2_Release.exe'
        }
    },
    methods: {
        downloadFile(event) {
            event.stopPropagation();
        }
    }
}
</script>
