<template>
    <div id="Member_main">
        <Header />
        <div class="membersDiv" id="Member">
            <div class="members_Container">
                <div class="members_title">
                    <h4>임직원 소개</h4>
                    <p>BIMPeers를 이끄는 임직원을 소개합니다.</p>
                </div>
    
                <div class="thumbnail_Container">
                    <ul class="thumbnail_List">
                        <li class="thumbnail_Tit">
                            CEO
                        </li>
        
                        <li class="thumbnail_images">
                            <img src="../../assets/images/profile/kg.png" alt="profileImg" />
        
                            <div class="profile_text">
                                <p class="name">김 경</p>
                                <p class="team">대표</p>
                            </div>
                        </li>
                    </ul>

                    <ul class="thumbnail_List">
                        <li class="thumbnail_Tit">
                            기술연구소
                        </li>
        
                        <li class="thumbnail_images">
                            <img src="../../assets/images/profile/lsj.png" alt="profileImg" />
        
                            <div class="profile_text">
                                    <p class="name">이 순 정 소장</p>
                                    <p class="team">기술연구소</p>
                            </div>
                        </li>

                        <li class="thumbnail_images">
                            <img src="../../assets/images/profile/kdj.png" alt="profileImg" />
        
                            <div class="profile_text">
                                <p class="name">김 대 진 과장</p>
                                <p class="team">기술연구소</p>
                            </div>
                        </li>

                        <li class="thumbnail_images">
                            <img src="../../assets/images/profile/cdg.png" alt="profileImg" />
        
                            <div class="profile_text">
                                <p class="name">조 동 근 대리</p>
                                <p class="team">기술연구소</p>
                            </div>
                        </li>
                                
                        <li class="thumbnail_images">
                            <img src="../../assets/images/profile/hyr.png" alt="profileImg" />
                            <div class="profile_text">
                                <p class="name">한 예 린 사원</p>
                                <p class="team">기술연구소</p>
                            </div>
                        </li>

                        
                        <li class="thumbnail_images">
                            <img src="../../assets/images/profile/jys.png" alt="profileImg" />
                            <div class="profile_text">
                                <p class="name">장 연 서 사원</p>
                                <p class="team">기술연구소</p>
                            </div>
                        </li>
                        
                    </ul>

                    <ul class="thumbnail_List">
                        <li class="thumbnail_Tit">
                            BIM 솔루션1팀
                        </li>
        
                        <li class="thumbnail_images">
                            <img src="../../assets/images/profile/pjh.png" alt="profileImg" />
        
                            <div class="profile_text">
                                    <p class="name">박 준 형 차장</p>
                                    <p class="team">BIM 솔루션1팀</p>
                            </div>
                        </li>

                        <li class="thumbnail_images">
                            <img src="../../assets/images/profile/rsh.png" alt="profileImg" />
        
                            <div class="profile_text">
                                <p class="name">류 수 형 주임</p>
                                <p class="team">BIM 솔루션1팀</p>
                            </div>
                        </li>

                        <li class="thumbnail_images">
                            <img src="../../assets/images/profile/psj.png" alt="profileImg" />
        
                            <div class="profile_text">
                                <p class="name">박 승 준 주임</p>
                                <p class="team">BIM 솔루션1팀</p>
                            </div>
                        </li>

                        <li class="thumbnail_images">
                            <img src="../../assets/images/profile/car.png" alt="profileImg" />
        
                            <div class="profile_text">
                                <p class="name">조 아 라 사원</p>
                                <p class="team">BIM 솔루션1팀</p>
                            </div>
                        </li>

                        <li class="thumbnail_images">
                            <img src="../../assets/images/profile/khj.png" alt="profileImg" />
        
                            <div class="profile_text">
                                <p class="name">김 현 지 사원</p>
                                <p class="team">BIM 솔루션1팀</p>
                            </div>
                        </li> 
                    </ul>

                    <ul class="thumbnail_List">
                        <li class="thumbnail_Tit">
                            BIM 솔루션2팀
                        </li>
        
                        <li class="thumbnail_images">
                            <img src="../../assets/images/profile/jjl.png" alt="profileImg" />
        
                            <div class="profile_text">
                                    <p class="name">장 재 림 과장</p>
                                    <p class="team">BIM 솔루션2팀</p>
                            </div>
                        </li>

                        <li class="thumbnail_images">
                            <img src="../../assets/images/profile/ldh.png" alt="profileImg" />
        
                            <div class="profile_text">
                                <p class="name">이 다 희 대리</p>
                                <p class="team">BIM 솔루션2팀</p>
                            </div>
                        </li>

                        <li class="thumbnail_images">
                            <img src="../../assets/images/profile/bsm.png" alt="profileImg" />
        
                            <div class="profile_text">
                                <p class="name">백 승 민 대리</p>
                                <p class="team">BIM 솔루션2팀</p>
                            </div>
                        </li>

                        <li class="thumbnail_images">
                            <img src="../../assets/images/profile/bdi.png" alt="profileImg" />
        
                            <div class="profile_text">
                                <p class="name">백 대 일 주임</p>
                                <p class="team">BIM 솔루션2팀</p>
                            </div>
                        </li>
                    </ul>

                    <ul class="thumbnail_List">
                        <li class="thumbnail_Tit">
                            전략기획팀
                        </li>
        
                        <li class="thumbnail_images">
                            <img src="../../assets/images/profile/kyh.png" alt="profileImg" />
        
                            <div class="profile_text">
                                    <p class="name">김 용 하 과장</p>
                                    <p class="team">전략기획팀</p>
                            </div>
                        </li>

                        <li class="thumbnail_images">
                            <img src="../../assets/images/profile/lsm.png" alt="profileImg" />
        
                            <div class="profile_text">
                                <p class="name">이 승 민 주임</p>
                                <p class="team">전략기획팀</p>
                            </div>
                        </li>

                        <li class="thumbnail_images">
                            <img src="../../assets/images/profile/cyc.png" alt="profileImg" />
        
                            <div class="profile_text">
                                <p class="name">조 영 찬 사원</p>
                                <p class="team">전략기획팀</p>
                            </div>
                        </li>

                        <li class="thumbnail_images">
                            <img src="../../assets/images/profile/pts.png" alt="profileImg" />
        
                            <div class="profile_text">
                                <p class="name">박 태 석 사원</p>
                                <p class="team">전략기획팀</p>
                            </div>
                        </li>

                        <li class="thumbnail_images">
                            <img src="../../assets/images/profile/lha.png" alt="profileImg" />
        
                            <div class="profile_text">
                                <p class="name">이 현 아 사원</p>
                                <p class="team">전략기획팀</p>
                            </div>
                        </li>

                        <li class="thumbnail_images">
                            <img src="../../assets/images/profile/hanwr.png" alt="profileImg" />
        
                            <div class="profile_text">
                                <p class="name">한 우 리 사원</p>
                                <p class="team">전략기획팀</p>
                            </div>
                        </li>
                    </ul>

                    <ul class="thumbnail_List">
                        <li class="thumbnail_Tit">
                            경영지원팀
                        </li>
        
                        <li class="thumbnail_images">
                            <img src="../../assets/images/profile/khs.png" alt="profileImg" />
        
                            <div class="profile_text">
                                    <p class="name">김 현 선 과장</p>
                                    <p class="team">경영지원팀</p>
                            </div>
                        </li>

                        <li class="thumbnail_images">
                            <img src="../../assets/images/profile/hjh.png" alt="profileImg" />
        
                            <div class="profile_text">
                                <p class="name">홍 지 희 대리</p>
                                <p class="team">경영지원팀</p>
                            </div>
                        </li>
                    </ul>

                    <div class="Bottom_Div">
                        <div class="bottom_Tit">
                            <h3>함께 만들어가는 <strong>BIMPeers</strong></h3>
                        </div>
                        <div class="slider-container">
                            <div>
                                <transition-group name="slider" mode="out-in">
                                    <img :key="currentIndex" :src="require(`@/assets/images/members/${currentImage}`)" :alt="currentImage">
                                </transition-group>
                            </div>
                            
        
                            <div class="Slider_Txt">
                                <h3>사내 동아리</h3>
                                <p>임직원의 친목도모와 건강증진을 위해 사내 동아리를 운영하고 있습니다.</p>
                            </div>
                        </div>

                        <div class="slider-container">
                            <div>
                                <transition-group name="slider" mode="out-in">
                                <img :key="currentIndex2" :src="require(`@/assets/images/members/event/${currentImage2}`)" :alt="currentImage2">
                                </transition-group>
                            </div>
                            
                    
                            <div class="Slider_Txt">
                                <h3>기념행사&nbsp;<span>('24년 송년회&16주년 창립기념일)</span></h3>
                                <p>화합의 장을 통해 임직원분들의 노고에 감사를 전하고, 소통과 연합의 시간을 가졌습니다.</p>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
            <Footer />
        </div>
        
    </div>
</template>

<style scoped>
@import '../../assets/css/members.css';
#Footer_Wrapper {
    position: absolute;
}
</style>

<script>
import Header from "../../components/PageHeader.vue"
import Footer from "../../components/PageFooter.vue"
import { ref, computed, onMounted, watch } from 'vue';

export default {
    name: 'App',
    components: {
        Header,
        Footer
    },
    setup() {
        const images = [
            'm1.png',
            'm2.png',
            'm4.png',
            'm5.png',
        ];
        const images2 = [
            '1.png',
            '2.png',
            '3.png',
            '4.png',
            '5.png',
            '6.png',
            '7.png',
            '8.png',
            '9.png'
        ]
        const currentIndex = ref(0);
        const currentIndex2 = ref(0)

        const currentImage = computed(() => {
            return images[currentIndex.value];
        });

        const currentImage2 = computed(() => {
            return images2[currentIndex2.value];
        });

        const startSlideShow = () => {
            setInterval(nextImage, 3500);
        };

        const startSlideShow2 = () => {
            setInterval(nextImage2, 3500);
        };

        const nextImage = () => {
            currentIndex.value = (currentIndex.value + 1) % images.length;
        };

        const nextImage2 = () => {
            currentIndex2.value = (currentIndex2.value + 1) % images2.length;
        };

        onMounted(() => {
            startSlideShow();
            startSlideShow2();
        });

        watch(currentIndex, (newIndex) => {
            console.log('Current Index:', newIndex);
        });

        return {
            currentIndex,
            currentImage,
            currentIndex2,
            currentImage2
        };
    },
    data() {
        return {
        showAbout_C: false,
        showS_category: false,
        };
    },
    methods: {
        toggleAbout_C() {
        this.showAbout_C = !this.showAbout_C;
        },
        toggleAboutSub_C() {
        this.showS_category = !this.showS_category;
        },
    },
};
</script>