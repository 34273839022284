<template>
    <div id="RecruitList_main">
        <Header />

        <div class="RecruitList_Div">
            <div class="Recruit_Container">
                <div class="RecruitList_Title">
                    
                    <h2>채용정보</h2>
                    <p>BIMPeers와 함께 꿈을 이루어나갈 분들을 찾습니다.</p>
                </div>
        
                <div class="RL_List">
                    <!-- <p class="total">{{ jsonData.length }}개의 채용공고가 등록되어 있습니다.</p> -->
                
                    <div v-for="item in jsonData" :key="item.code" class="RL_Container">
                        <p style="width:70px; height:35px; background:#787878; border-radius:0 10px 0 10px; position:relative; top:0; left:calc(100% - 70px); display:flex; align-items:center; justify-content:center; font-weight:bold; font-size:1.2rem; color:#fff">D-{{ remainDays }}</p>
                        <div class="titBox">
                            <h2 class="tit">
                                <span>{{ item.name }}</span>
                            </h2>

                            <ul>
                                <li>경&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;력 | <strong>{{ item.career}}</strong></li>
                                <li>담당업무 |  <strong>{{ item.part}}</strong></li>
                                <li>전&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;공 |  <strong>{{ item.major}}</strong></li>
                            </ul>

                            <!-- <p class="linkTit">입사지원 가능 링크</p> -->
                            <div class="links_Container">
                                <p>* 클릭시 해당 채용정보 페이지로 이동합니다</p>
                                <div class="links">    
                                    <a :href="item.link">
                                        잡코리아&nbsp;&nbsp;&nbsp;&nbsp;<img src="../../assets/images/icons/jobkorea.png" alt="jbkorea">
                                    </a>
    
                                    <a :href="item.link2">
                                        사람인&nbsp;&nbsp;&nbsp;&nbsp;<img src="../../assets/images/icons/saramin.png" alt="saramin">
                                    </a>
                                </div>
                            </div>
                           
                
                            <p class="work">{{ item.work }}, {{ item.ability }}</p>
                        </div>
                
                        <br>
                        <p class="lang">{{ item.lang}}, {{ item.location }}</p>
                    </div>
                </div> 
            </div>
            
        </div>
        <Footer />
    </div>

</template>

<style scoped>
    @import "../../assets//css//recruitList.css";
    #FooterMain {
        grid-row:6;
    }
</style>


<script>
import Header from "../../components/PageHeader.vue"
import Footer from "../../components/PageFooter.vue"
import recruitList from "../../assets/tables/recruitList.json"
//import axios from "axios";

export default { 
    name:'App',
    components: {
        Header,
        Footer
    },
    data() {
    return {
            jsonData: recruitList,
            showAbout_C:false,
            showS_category:false,
            jobPostingEndDate:'2024-03-03' //채용공고 마감일
            //totalType: 1,
            //typeName: "",
            //Types: [],
        };
    },

    mounted () {
        this.jsonData = recruitList;
        this.name = this.$route.params.name;
    },



    methods:{     
        getConfigUrl(item) {
        console.log('Item Name:', item.name);

        const recruitName = encodeURIComponent(item.name); // Encode the project name to handle special characters in the URL
        const url = `/recruit/list/${recruitName}`;
        console.log('Generated URL:', url);

        return url;
        

    },

        toggleAbout_C() {
            this.showAbout_C = !this.showAbout_C;
        },
        toggleAboutSub_C() {
            this.showS_category = !this.showS_category;
        },
    },

    computed: {
        //채용공고 마감일 D-Day 계산
        remainDays() {
            const today = new Date() ;
            const endDate = new Date (this.jobPostingEndDate);

            const timeDiff = endDate.getTime() - today.getTime();
            const daysDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));

            return daysDiff >= 0 ? daysDiff: 0;
        }
    }
}
</script>