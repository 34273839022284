<template>
    <div id="contact_main">
        <PageHeader />
        <div class="contact_Wrapper" id="Contact">
            <div class="contact_Container">
                <div class="contact_tit">
                    <h4>오시는 길</h4>
                    <h2>Contact</h2>
                    <p><strong>언제나 함께하는 </strong>BIMPeers</p>
                </div>

                <div class="contact_Contents">
                    <div class="address">
                        <div class="address_table">
                            <h4>BIMPeers</h4>
                            <ul class="addressWrap">
                                <!-- <li class="txt">주소</li> -->
                                <li>서울특별시 서초구 서운로 26-1, 보일빌딩 501 & 601호</li>
                                <li>501 & 601, Seoun-ro, Seocho-gu, Seoul</li>
                                
                            </ul>
                        </div>
    
                        <div class="Contact_table">
                            <h4>
                                제품 및 커스터마이징 문의</h4>
                            <ul class="addressWrap">
                                <li>이메일&nbsp;&nbsp;
                                    <a :href="'mailto:' + email">help@bimpeers.com</a>
                                </li>
                                <li>전&nbsp;&nbsp;&nbsp;&nbsp;화&nbsp;&nbsp;
                                    <a :href="'tel:' + phoneNumber">070-7767-2505</a>
                                </li>
                            </ul>
                        </div>
    
                    </div>
    
    
                    <div class="mapDiv">
                        <div class="map">
                        
                        </div>
                    </div>

                    <div class="contact_Info">
                        <div class="Transportation_Container">
                            <h4>
                                <img src="../../assets//images/icons/bus.png" alt="">
                                대중교통 이용시
                            </h4>
    
                            <ul>
                                <li class="busSub">지하철 이용시</li>  
                                <li>양재역 (신분당선 / 3호선) 하차</li>
                                <li class="busSub">버스 이용시</li>  
                                <li>광역버스 | <strong>[뱅뱅사거리 정류장]</strong> 하차</li>
                                <li>마을버스 | <strong>[서초문화정보예술학교 후문 정류장]</strong> 하차</li>
                            </ul>
                        </div>
    
                        <div class="Car_Container">
                            <h4>
                                <img src="../../assets//images/icons/car.png" alt="">
                                자차 이용시
                            </h4>
    
                            <ul>
                                <li class="busSub">고속도로 이용시 (지방에서 오시는 경우)</li>  
                                <li>용인서울고속도로 - 경부고속도로 - 서초ic - 서초구청 사거리에서 좌회전 - 보일빌딩 진입</li>
                                <li class="busSub">고속도로 이용시 (서울에서 오시는 경우)</li>  
                                <li>올림픽대로 - 경부고속도로 - 서초ic - 서초구청 사거리에서 좌회전 - 보일빌딩 진입</li>
                                <li class="check">※ 주차는 방문하시기 전에 미리 말씀을 주시면 됩니다.</li>
                            </ul>
                        </div>
                        
                    </div>
        
                </div>
            </div>
            
            



        </div>
        <PageFooter />

    </div>
</template>

<style scoped>
@import url('../../assets/css/contact.css');
</style>

<script>
import PageHeader from '../../components/PageHeader.vue';
import PageFooter from '../../components/PageFooter.vue';
//import MobileMap from '../../components/MobileMapComponent.vue'
export default { 
    name:'App',
    components: {
        PageHeader,
        PageFooter,
        //MobileMap
    },

    data() {
        return {
            email:'help@bimpeers.com',
            phoneNumber:'07077672505',
            showAbout_C:false,
            showS_category:false
        };
    },


    methods: {
        scrollTo(id) {
            document.getElementById(id).scrollIntoView({behavior:'smooth'})
        },
        toggleAbout_C() {
            this.showAbout_C = !this.showAbout_C;
        },
        toggleAboutSub_C() {
            this.showS_category = !this.showS_category;
        },
    }
}
</script>
