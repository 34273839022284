<template>
    <div id="talentMain">
        <Header />
        <div class="peopleDiv">
            <div class="people_Container">
                <div class="peopleTitle_image">
                    <h2>BIMPeers 인재상</h2>
                    <p>성장을 위한 가장 확실한 방법은<br>뛰어난 동료들 사이에 있는 것 입니다.</p>
                </div>
                
    
                <div class="peopleText">
                    <img :src="getImageSource">
                </div>
            </div>
        </div>
        <Footer />
    </div>

</template>

<style scoped>
    @import "../../assets/css/talent.css";

</style>

<script>
import Header from '../../components/PageHeader.vue';
import Footer from '../../components/PageFooter.vue'
export default {
    name:'App',
    components: {
        Header,
        Footer
    },
    data() {
    return {
            showAbout_C:false,
            showS_category:false
        };
    },
    methods: {                                                                         
        toggleAbout_C() {
            this.showAbout_C = !this.showAbout_C;
        },
        toggleAboutSub_C() {
            this.showS_category = !this.showS_category;
        },
    },
    computed: {
        getImageSource() {
        // Check the screen width and return the appropriate image path
            return window.innerWidth >= 1200 ? require("../../assets/images/people/talentWide.png") : require("../../assets/images/people/ttt.png");
        }
    }

}
</script>
