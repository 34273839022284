// 모바일 프로젝트 main.js
import { createApp } from 'vue';
import App from './App.vue';
import router from './router/router';
import '../src/router/redirect.js'
// const isMobile = () => {
//   return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
// }

// if (isMobile()) {
//   window.location.replace('https://test-service.bimpeers.com:5011');
// } else {
//   window.location.replace('https://test-service.bimpeers.com:5007');
// }


const app = createApp(App);

// 모바일에서는 HTTPS를 사용하지 않는다고 가정
// const baseURL = isMobile() ? 'http://test-service.bimpeers.com:5011' : 'http://test-service.bimpeers.com:5007';
// app.config.globalProperties.$apiBaseURL = baseURL;

app.use(router).mount('#app');
