<template>
    <div id="DpMain">
        <Header />
        <div class="Dp_Wrapper">
            <div class="Dp_Container">
                <div class="DpTitle">
                    <h4>인사제도</h4>
                    <p>구성원이 창의성과 역량을 자발적으로<br>
                        발휘할 수 있도록
                        <strong>성과 중심의 공정한 인사관리</strong>와<br>
                        <strong>수평적이고 유연한 조직문화</strong>를 추구합니다.
                    </p>
                </div>

                <div class="Dp_Lists">
                    <!-- 평가제도 start -->
                    <div class="evDiv">
                        <div class="evTitle">
                            <h2>평가제도</h2>
                            <p>"평가결과는 보상/승진 제도와 연계되어 활용되며,<br>
                                평가 종료 후 개인별 피드백을 통해 공정성을 높이고,<br> 개인의 성장발전을 추구합니다"</p>
                        </div>
        
                        <div class="evContent"> 
                            <img src="../../assets/images/people/test.png" alt="">
                        </div>
                    </div>
                    <!-- 평가제도 end -->
        
                    <!-- 직위체계 start -->
                    <div class="psDiv">
                        <div class="psTitle">
                            <h2>직위체계</h2>
                            <p>"연공서열이 아닌 개인의 성과와 역량에 기반한<br>직위체계를 구축하고 있습니다.</p>
                        </div>
        
                        <div class="psContent">
                            <img src="../../assets/images/department/123.png" alt="">
                        </div>
                    </div>
                    <!-- 직위체계 end -->
        
                    <!--  복리후생 start -->
                    <div class="wfDiv">
                        <div class="wfTitle">
                            <h2>복리후생</h2>
                            <p>BIMPeers는 건강한 업무환경, 지속적인 육성과 성장,<br> 가족과 함께 누리는 헤택을 중시합니다.</p>
                        </div>
        
                        <div class="wfContent">
                            <img src="../../assets/images/people/sss.png" alt="">
        
                        </div>
                    </div>
                    <!-- 복리후생 end -->
                </div>
            </div>
        </div>
        <Footer />
    </div>
</template>

<style scoped>
@import url('../../assets/css/department.css');
</style>


<script>
import Header from '../../components/PageHeader.vue'
import Footer from '../../components/PageFooter.vue'
    export default {
        name:'App',
        components: {
            Header,
            Footer
        },

        data() {
        return {
                showAbout_C:false,
                showS_category:false
            };
        },
        methods: {                                                                         
            toggleAbout_C() {
                this.showAbout_C = !this.showAbout_C;
            },
            toggleAboutSub_C() {
                this.showS_category = !this.showS_category;
            },
        },
    }

</script>

