<template>
    <div id="history_main">
        <PageHeader />
        <div class="history_Wrapper">
            <div class="history_Container">
                <div class="history_title">
                    <h2>회사연혁</h2>
                    <p>
                    BIMPeers는 계속해서 새로운 길을<br>만들어 가고 있습니다.<br /><br>
                    겉으로 보이는 화려함을 추구하기 보다는<br>내실 있는 회사가 되도록 노력하겠습니다.
                    </p>
                </div>

            <!-- <p class="columnBar"></p> -->
                <div class="history_contents" ref="content">
                    <div class="left_Content">
                        <div class="Content_Wrapper">
                            <div class="Content_Container">
                                <h4 class="year">2023</h4>
                                <ul>
                                    <li class="month">01</li>
                                    <li>삼우종합건축사사무소 연간계약<br>(BIM 플러그인 개발) 체결</li>
                                </ul>
                            </div>

                            <div class="Content_Container">
                                <h4 class="year">2019</h4>
                                <ul>
                                    <li class="month">12</li>
                                    <li>삼성엔지니어링 업무협약(MOU)체결</li>
                                </ul>
                            </div>

                            <div class="Content_Container">
                                <h4 class="year">2017</h4>
                                <ul>
                                    <li class="month">03</li>
                                    <li>국토교통과학기술진흥원 5개년 국가연구개발사업<br>(개방형 BIM 기반의 건축설계 자동화지원 기술 및 첨단 유지관리 기반 기술 개발) 수행</li>
                                </ul>
                            </div>

                            <div class="Content_Container">
                                <h4 class="year">2016</h4>
                                <ul>
                                    <li class="month">05</li>
                                    <li>XENOPAN(대한민국 대표 프로젝트 메신저) 개발</li>
                                </ul>
                            </div>

                            <div class="Content_Container">
                                <h4 class="year">2014</h4>
                                <ul>
                                    <li class="month">12</li>
                                    <li>기업부설연구소 설립</li>
                                </ul>

                                <ul>
                                    <li class="month">01</li>
                                    <li>Revit 데이터 연동 Web Viewer 개발</li>
                                </ul>
                            </div>

                            <div class="Content_Container">
                                <h4 class="year">2010</h4>
                                <ul>
                                    <li class="month">03</li>
                                    <li>벤처기업 인증</li>
                                </ul>
                            </div>

                            <div class="Content_Container">
                                <h4 class="year">2008</h4>
                                <ul>
                                    <li class="month">06</li>
                                    <li>Build London Live 2008<br>(Use Of Interoperability 상) 수상</li>
                                </ul>

                                <ul>
                                    <li class="month">02</li>
                                    <li>BIMPeers 설립</li>
                                </ul>
                            </div>
                            <!-- <h4>{{  }}</h4>
                            <p><strong>{{ item.month }}</strong>{{ item.description }}</p>
                            <p><strong>{{ item.month2 }}</strong>{{ item.description2 }}</p> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <PageFooter />
    </div>
</template>

<style scoped>
@import '../../assets/css/history.css';
</style>

<script>
import PageHeader from '../../components/PageHeader.vue';
import PageFooter from '../../components/PageFooter.vue'

export default {
    name: "App",
    components: {
        PageHeader,
        PageFooter
    },
    data() {
        return {
        };
    },
};
</script>
