<template>
    <div id="Pr_main">
        <Header />
            <div class="Project_Wrapper">
                <div class="Project_Container">
                    <div class="Project_Tit">
                        <h2>수행 프로젝트</h2>
                        <p>
                            BIMPeer는<strong>기술선도와 품질경영으로 </strong><br>15년 동안 고객사와 함께 성장해 왔습니다.
                        </p>
                    </div>
    
                    <div class="Project_Contents">
                        

                        <div class="prTableDiv">
                            <div class="projectTable">
                                <p class="prStatus">
                                    <strong>{{ filteredProjectTables.length }}</strong>
                                    개의 프로젝트가 등록되어 있습니다.
                                </p>

                                <div class="Proj_ComboBox">
                                    <div class="Proj_ComboBox">
                                        <div class="FirstSelect_Container">
                                            <select v-model="selectedOption" class="FirstSelect_Div">
                                                <option disabled value="">====== 선택하세요 ======</option>
                                                <option value="all">전체</option>
                                                <option value="year">연도별</option>
                                                <option value="keyword">키워드별</option>
                                                <option value="client">고객사별</option>
                                            </select>
                                        </div>
                                        
                                        <div class="SecondSelect_Container">
                                            <select v-model="selectedFilter" class="SecondSelect_Div">
                                            <option disabled value="">====== 선택하세요 ======</option>
                                            <option v-for="item in filteredOptions" :key="item" :value="item">{{ item }}</option>
                                            </select>
                                        </div>
                                    </div>
        
                                    <div v-if="selectedKey" class="SecondGroup_Container">
                                    </div>
        
                                </div>
                                
                                <div class="project_list">
                                    <div class="project_Tit" v-for="item in paginatedOptions" :key="item.code">
                                        <!-- <router-link v-if="item.isPublic" :to="{ name: 'ProjectDetail', params: { name: encodeURIComponent(item.name) } }" @click="storeFilters"
                                        style="width:40px; height:30px; background:#787878; border-radius: 0 10px 0 0; position:relative; top:0; right:0; display:flex; align-items:center; justify-content:center; color:#fff; font-size:16px">
                                                ->
                                        </router-link> -->
                                        <div class="Pr_Cards" style="width:260px; height:165px;  display:flex; flex-direction: column; align-items:flex-start; justify-content:center;">
                                            <h2 class="name">
                                                <router-link
                                                    v-if="item.isPublic"
                                                    :to="{ name: 'ProjectDetail', params: { name: encodeURIComponent(item.name) } }" @click="storeFilters">
                                                    {{ item.name }}
                                                </router-link>
                                                <span v-else>{{ item.name }}</span>
                                            </h2>
                                            <p style="color:#787878; font-size:1rem;">{{ item.subName}}</p>
                                            <p class="duration">{{ item.duration }}</p>
                                            <p class="client">{{ item.client }}</p>
                                            <p class="tags">[ Keywords ]&nbsp;&nbsp;&nbsp;<strong>{{ item.keyword }}</strong></p>
                                        </div>

                                        <router-link v-if="item.isPublic" :to="{ name: 'ProjectDetail', params: { name: encodeURIComponent(item.name) } }" @click="storeFilters"
                                        style="width:40px; height:40px; border-radius:50%; background:#787878; display:flex; align-items:center; justify-content:center; color:#fff; font-size:16px">
                                                ->
                                        </router-link>
                                    </div>
                                </div>
                            </div>
    
                            <div class="pagination">
                                <ul>
                                    <li>
                                        <button @click="goToFirstPage" :disabled="currentPage === 1">
                                        <img src="../assets/images/icons/paging_f.png" alt="">
                                        </button>
                                    </li>
                            
                                    <li>
                                        <button @click="currentPage = Math.max(1, currentPage - 1)">
                                        <!-- <img src="../../assets/images/icons/paging.png" alt=""> -->
                                        </button>
                                    </li>
                            
                                    <li v-for="pageNumber in displayedPageNumbers" :key="pageNumber" :class="{ active: pageNumber === currentPage }">
                                        <button @click="currentPage = pageNumber">{{ pageNumber }}</button>
                                    </li>
                            
                                    <li>
                                        <button @click="currentPage = Math.min(totalPages, currentPage + 1)">
                                        </button>
                                    </li>
                            
                                    <li>
                                        <button @click="goToLastPage" :disabled="currentPage === filteredTotalPages">
                                            <img src="../assets/images/icons/paging_f.png" alt="" class="Last">
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
     
                </div>
            </div>
        <Footer />
    </div>
</template>

<style Scoped>
@import "../assets/css/project.css";
</style>



<script>
import Header from "../components/PageHeader.vue"
import Footer from "../components/PageFooter.vue"
import ProjectTable from "../assets/tables/ProjectTable.json";



export default { 
    
    name:'App',
    components: {
        Header,
        Footer,
    },
    data() {
        return {
            client:"",
            jsonData: ProjectTable,
            pageSize: 6,
            currentPage: 1,
            selectedPr:'',
            selectedCl:'',
            selectedKey:'',
            selectedOption:'all',
            selectedFilter:'',
            filteredOptionsData:[],
            filters: {
                name:null,
                client:null,
                duration:null,
                keyword:null,
                year:null,
                code:null,
                isPublic:null
            }
        };
    },

    mounted () {
        this.name = this.$route.params.name;
        this.jsonData = ProjectTable;
        this.populateSecondComboBox();
    },
    watch: {
        selectedOption: function(newOption, oldOption) {
            // Call the method to populate the second combo box when the selected option changes
            if (newOption !== oldOption) {
                this.populateSecondComboBox();
            }
        },
    },



    computed: {
    isViewAllSelected() {
        return this.selectedOption === 'all';
    },

    paginatedOptions() {
        if (this.isViewAllSelected) {
            return this.jsonData.slice(
            (this.currentPage - 1) * this.pageSize,
            this.currentPage * this.pageSize
            );
        } else {
            if (this.selectedOption === 'year') {
            return this.jsonData
                .filter((item) => item.year === this.selectedFilter)
                .slice(
                (this.currentPage - 1) * this.pageSize,
                this.currentPage * this.pageSize
                );
            } else if (this.selectedOption === 'keyword') {
            return this.jsonData
                .filter((item) => item.keyword.includes(this.selectedFilter))
                .slice(
                (this.currentPage - 1) * this.pageSize,
                this.currentPage * this.pageSize
                );
            } else if (this.selectedOption === 'client') {
            return this.jsonData
                .filter((item) => item.client === this.selectedFilter)
                .slice(
                (this.currentPage - 1) * this.pageSize,
                this.currentPage * this.pageSize
                );
            } else {
            return [];
            }
        }
    },

    paginatedProjects() {
    const startIndex = (this.currentPage - 1) * this.pageSize;
    const endIndex = startIndex + this.pageSize;
    return this.filteredProjectTables.slice(startIndex, endIndex);
    },

    filteredProjectTables() {
        if (this.selectedOption === 'all') {
            return this.jsonData;
        } else if (this.selectedOption === 'year') {
            return this.jsonData.filter((item) => item.year === this.selectedFilter);
        } else if (this.selectedOption === 'keyword') {
            return this.jsonData.filter((item) => item.keyword.includes(this.selectedFilter));
        } else if (this.selectedOption === 'client') {
            return this.jsonData.filter((item) => item.client === this.selectedFilter);
        } else {
            return [];
        }
    },

    filteredOptions() {
        if (this.selectedOption === 'all') {
            return [];
        } else if (this.selectedOption === 'year') {
            const years = this.jsonData.map((item) => item.year);
            return [...new Set(years)];
        } else if (this.selectedOption === 'keyword') {
            const keywords = this.jsonData.flatMap((item) => item.keyword.split(" | "));
            return [...new Set(keywords)];
        } else if (this.selectedOption === 'client') {
            const clients = this.jsonData.map((item) => item.client);
            return [...new Set(clients)];
        } else {
            return [];
        }
    },

    filteredTotalPages() {
        return Math.ceil(this.filteredProjectTables.length / this.pageSize);
    },

    displayedPageNumbers() {
        const totalPages = this.filteredTotalPages;
        const current = this.currentPage;
        const pageRange = 7;

        let start = Math.max(1, current - Math.floor(pageRange / 2));
        let end = Math.min(start + pageRange - 1, totalPages);

        if (end - start + 1 < pageRange) {
        start = Math.max(1, end - pageRange + 1);
        }

        return Array(end - start + 1)
        .fill()
        .map((_, index) => start + index);
    },
},


methods: {
    //localStorage에 저장..
    storeFilters() {
        localStorage.setItem('filterSelections', JSON.stringify(this.filters));
    },
    getConfigUrl(item) {
        console.log('Item Name:', item.name);

        const projectName = encodeURIComponent(item.name); // Encode the project name to handle special characters in the URL
        const url = `/project/${projectName}`;
        console.log('Generated URL:', url);

        return url;
    },
    populateSecondComboBox() {
            // Check the selectedOption and populate the second combo box accordingly
            if (this.selectedOption === 'year') {
            // Filter the options for the second combo box based on the selected year
            this.filteredOptionsData = this.jsonData
                .filter((item) => item.year === this.selectedFilter)
                .map((item) => item.client);
            } else if (this.selectedOption === 'keyword') {
            // Filter the options for the second combo box based on the selected keyword
            // Assuming your keywords are separated by " | " as you have in the filteredOptions() method
            this.filteredOptionsData = this.jsonData
                .filter((item) => item.keyword.includes(this.selectedFilter))
                .map((item) => item.client);
            } else if (this.selectedOption === 'client') {
            // Filter the options for the second combo box based on the selected client
            this.filteredOptionsData = this.jsonData
                .filter((item) => item.client === this.selectedFilter)
                .map((item) => item.year);
            } else {
            // If 'all' is selected or no option is selected, show all options for the second combo box
            this.filteredOptionsData = [];
            }
        },

        
    
    goToFirstPage() {
    this.currentPage = 1;
    },
    goToLastPage() {
    this.currentPage = this.filteredTotalPages;
    },

    clickYear () {
        this.showYear = !this.showYear;
        this.showCl = false;
        this.showKey = false;
    },  
    clickClient () {
        this.showCl = !this.showCl;
        this.showYear = false;
        this.showKey = false;
    },
    clickKeywords () {
        this.showKey = !this.showKey;
        this.showYear = false;
        this.showCl = false;
    },
    sortTable(columnName) {
        if (this.sortColumn === columnName) {
            // toggle sort direction if same column is clicked
            this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
        } else {
            // sort by new column
            this.sortColumn = columnName;
            this.sortDirection = 'asc';
        }
        
        this.jsonData.sort((a, b) => {
            const valueA = a[columnName].toUpperCase(); // convert to uppercase
            const valueB = b[columnName].toUpperCase(); // convert to uppercase
            if (valueA > valueB) {
            return this.sortDirection === 'asc' ? 1 : -1;
            } else if (valueA < valueB) {
            return this.sortDirection === 'asc' ? -1 : 1;
            } else {
            return 0;
            }
        });
    }
}
}
</script>

