<template>
    <div class="Footer_Wrapper">
        <div class="Footer_Container">
            <p class="Footer_Tit">BIMPeers</p>

            <div class="Footer_List">
                <ul class="Major">
                    <li>건축 / 건설분야 소프트웨어 개발 및 서비스</li>
                </ul>
                
                <ul class="Address">
                    <li>서울특별시 서초구 서운로 26-1, 501 / 601호</li>
                    <li>501/601, Seoun-ro, Seocho-gu, Seoul</li>
                    <li>070-7767-2505 | help@bimpeers.com</li>
                </ul>

                <!-- <ul class="Tel">
                    <li>070-7767-2505 | help@bimpeers.com</li>
                </ul> -->
            </div>

            
        </div>
    </div>
</template>

<style scoped>

.Footer_Wrapper {
    position: relative;
    bottom:0;
    left:0;
    width: 100%;
    height: 140px;
    border-top:3px solid rgba(255,255,255,.25);
    background: #0F181F;
    grid-column-start: 1;
    grid-column-end: 12;
    grid-row:4;
    display: flex;
    grid-template-columns: 240px 1fr;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
}
.Footer_Container {
    width:100%;
    max-width: 353px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: flex-start;
    margin-left: 15px;

}
.Footer_List {
    display: flex;
    flex-direction: column;
}
.Footer_Tit {
    font-size:1.2rem;
    font-weight:bold;
    font-family: "Pretendard, sans-serif";
    color:#fff;
    padding-bottom: 7px;
}
.Footer_Container ul li {
    font-family: "Pretendard, sans-serif";
    font-size:.7rem;
    line-height:px;
    color:#fff;
    font-weight:lighter
}
.Address {
    list-style: none;
    line-height: 16px;
}
.Major {
    margin-bottom: 8px;
}
.Major li {
    font-size: 16px !important;
    margin-bottom: 8px;
}
.highlighted {
    /* background: #fff; */
    color: #4d8fcc !important;
    /* width: 100%;
    margin-left: 0 !important;
    padding-left: 44px; */
}
</style>