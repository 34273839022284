<template>
   <router-view></router-view>
</template>

<style>
@font-face {
  font-family: 'Pretendard, sans-serif';
  src: url('./assets/fonts/Pretendard-Medium.ttf');
}

body {
  margin: 0;
  font-family: 'Pretendard, sans-serif';
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
</style>
