<template>
    <div id="MainMobile_Wrapper">
        <PageHeader />
        <div class="MainPage_Container">
            <div class="Section1_Wrapper">
                <div class="Section1_Container">
                    <h2>
                        <strong>빔피어스</strong> (BIMPeers) 는<br>
                        건축/건설분야에
                        소프트웨어를<br>개발하고 보급하는 회사입니다
                    </h2>

                    <div class="bg_Container">
                        <img src="../assets/images/bb.png" alt="">
                    </div>
                </div>
            </div>
        
            <div class="Section2_Wrapper">
                <div class="Section2_Container">
                    <div class="Section2_Content">
                        <div class="Section2_Txt">
                            <h2>
                                
                                <strong>Our Product</strong>
                                
                            </h2>

                            <p>BIMPeers는 프로젝트를 효율적으로 통합관리 할 수 있는 솔루션을 제공합니다.</p>
                            <!-- <span class="GoProject">프로젝트 바로가기</span> -->
                        </div>

                        <div class="Section2_List">
                            <ul id="bim-modeing">
                                <li>
                                    <router-link to="/product#bim-modeing" v-slot="{ navigate }">
                                        <h4 @click="navigate">BIM 모델링 자동화 프로그램</h4>
                                        <li>Revit 기반의 모델링 자동화 Add-in 소프트웨어 개발</li>
                                        <li>업무프로세스 분석 및 업무 자동화 컨설팅</li>
                                    </router-link>
                                </li>
                                
                                
                            </ul>

                            <ul id="collabo">
                                <li>
                                    <router-link to="/product#collabo" v-slot="{ navigate }">
                                        <h4 @click="navigate">협업지원시스템</h4>
                                        <li>건축, 건설, 엔지니어링 업체들의 사내 운영 시스템 개발</li>
                                        <li>게시판, 일정관리, 연락처 관리 등 맞춤형 개발</li>
                                    </router-link>
                            </li>
                                
                                
                            </ul>

                            <ul id="prManagement">
                                <li>
                                    <router-link to="/product#prManagement" v-slot="{ navigate }">
                                    <h4 @click="navigate">프로젝트 관리 시스템</h4>
                                    <li>용역 수행을 하는 회사들의 프로젝트 관리를 도와주는 시스템</li>
                                    <li>전자 결재, 프로젝트 투입 자원 분석, 참여인원들 간의 의사소통(메신저) 지원</li>
                                </router-link>
                                </li>
                                
                               
                            </ul>

                            <ul id="LMS">
                                <li>
                                    <router-link to="/product#LMS" v-slot="{ navigate }">
                                        <h4 @click="navigate">BIM 라이브러리 관리 시스템</h4>
                                        <li>라이브러리 관리 방안 컨설팅</li>
                                        <li>BIM 기반의 설계프로젝트 진행에 사용되는 라이브러리 통합관리시스템</li>
                                    </router-link>
                                </li>
                                
                                
                            </ul>

                            <ul id="other">
                                <li>
                                    <router-link to="/product#other" v-slot="{ navigate }">
                                        <h4 @click="navigate">기타 응용프로그램 개발</h4>
                                        <li>웹, 데스크탑, 모바일 기반의 솔루션 개발</li>
                                        <li>최신의 기술(Ajax, Rss, RIA 등)을 활용한 솔루션 구축 가능</li>
                                    </router-link>
                                </li>
                                
                                
                            </ul>
                            
                        </div>
                    </div>
                </div>
                
            </div>

            <div class="Section3_Wrapper" ref="section3">
                <div class="Section3_Container">
                    <div class="Client_List" ref="section3">
                        <img src="../assets/images/LogoW.png" alt="">
                        <img src="../assets/images/customers/gansam.gif" alt="">
                        <img src="../assets/images/customers/samsungEngi.gif" alt="">
                        <img src="../assets/images/customers/customer_29.gif" alt="">
                        <img src="../assets/images/customers/customer_28.gif" alt="">
                        <img src="../assets/images/customers/customer_27.gif" alt="">
                        <img src="../assets/images/customers/customer_26.gif" alt="">
                        <img src="../assets/images/customers/customer_25.gif" alt="">
                        <img src="../assets/images/customers/customer_24.gif" alt="">
                        <img src="../assets/images/customers/customer_23.gif" alt="">
                        <img src="../assets/images/customers/customer_22.gif" alt="">
                        <img src="../assets/images/customers/customer_21.gif" alt="">
                        <img src="../assets/images/customers/customer_20.gif" alt="">
                        <img src="../assets/images/customers/customer_19.gif" alt="">
                        <img src="../assets/images/customers/customer_18.gif" alt="">
                        <img src="../assets/images/customers/customer_17.gif" alt="">
                        <img src="../assets/images/customers/customer_16.gif" alt="">
                        <img src="../assets/images/customers/customer_15.gif" alt="">
                        <img src="../assets/images/customers/customer_14.gif" alt="">
                        <img src="../assets/images/customers/customer_13.gif" alt="">
                        <img src="../assets/images/customers/customer_12.gif" alt="">
                        <img src="../assets/images/customers/customer_11.gif" alt="">
                        <img src="../assets/images/customers/customer_10.gif" alt="">
                        <img src="../assets/images/customers/customer_9.gif" alt="">
                        <img src="../assets/images/customers/customer_8.gif" alt="">
                        <img src="../assets/images/customers/customer_7.gif" alt="">
                        <img src="../assets/images/customers/customer_6.gif" alt="">
                        <img src="../assets/images/customers/customer_5.gif" alt="">
                        <img src="../assets/images/customers/customer_4.gif" alt="">
                    </div>

                    <div class="Section3_Content">
                        <div class="Section3_Txt">
                            <h2>
                                <strong>기술선도와<br>품질경영</strong><br>으로 16년동안 고객사와 함께<br>성장해 왔습니다.
                            </h2>
                            <!-- <p>기술선도와 품질경영으로 15년동안 고객사와 함께 성장해 왔습니다.</p> -->
                        </div>

                        <div class="Section3_Graph">
                        
                            <div class="Graph_Project">
                                <div class="Graph_Project_Tit">
                                    <p>수행한 프로젝트</p>
                                    <router-link to="/project">
                                        <img src="../assets/images/router.png" alt="">
                                    </router-link>
                                    
                                </div>
                                
                                <h1>
                                    <strong ref="projectRef">0</strong>+
                                </h1>
                            </div>

                            <div class="Graph_Client">
                                <div class="Graph_Client_Tit">
                                    <p>함께한 고객사</p>
                                    <router-link to="/">
                                        <img src="../assets/images/router.png" alt="">
                                    </router-link>
                                </div>
                                
                                <h1>
                                    <strong ref="clientRef">0</strong>+
                                </h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <PageFooter />
    </div>
</template>

<style scoped>
@import '../assets/css/MainMobile.css';
.active {
    color:#4d8fcc
}
.Footer_Wrapper {
    position: relative;
}
</style>


<script>
import PageHeader from '../components/PageHeader.vue';
import PageFooter from '../components/PageFooter.vue';
export default {
    components: {
        PageHeader,
        PageFooter
    },

    data() {    
        return {
            isSideMenuVisible:false,
            showAbout:false,
            showPeople:false,
            showRecruit:false,
            section3Observer:null,
            isAnimationStarted:false,
            isAboutActive:false,
            isPeopleActive:false,
            isRecruitActive:false
        }
    },

    methods: {
        showSideMenu() {
            this.isSideMenuVisible = true;
        },
        hideSideMenu() {
            this.isSideMenuVisible = false;
        },
        
        clickSideMenu() {
            this.showSideMenu = !this.showSideMenu
        },
        clickAbout() {
            this.showAbout = !this.showAbout;
            this.isAboutActive = !this.isAboutActive
        },
        clickPeople() {
            this.showPeople = !this.showPeople;
            this.isPeopleActive = !this.isPeopleActive
        },
        clickRecruit() {
            this.showRecruit = !this.showRecruit;
            this.isRecruitActive = !this.isRecruitActive
        },
        closeMenu() {
            this.showSideMenu = !this.showSideMenu
        },

        handleIntersection(entries) {
            entries.forEach((entry) => {
                if (entry.isIntersecting) 
                {
                    this.isAnimationStarted = true;
                    // 요소가 화면에 나타났을 때 숫자 카운트 애니메이션 실행
                    this.animationNumber(150, this.$refs.projectRef);
                    this.animationNumber(60, this.$refs.clientRef, 1.2);
                    this.isAnimationStarted = true;

                    this.section3Observer.unobserve(this.$refs.section3);
                }
            });
        },

        animationNumber(target, ref, animationStep = 1) {
            let current = 0;
            const step = (target / 50) * animationStep; //count 애니메이션 단계

            const animation = () => {
                if (current < target) {
                    current += step;
                    ref.innerHTML = Math.round(current);
                    requestAnimationFrame(animation);
                } else {
                    ref.innerHTML = target;
                }
            };
            requestAnimationFrame(animation);
        },
    },

    mounted(){
        this.section3Observer = new IntersectionObserver(this.handleIntersection, {
            root: null, // viewport를 루트로 사용
            rootMargin: '0px', // 루트와의 마진
            threshold: 0.1, // 요소가 화면에 10% 이상 나타날 때 콜백 실행
        });
        this.section3Observer.observe(this.$refs.section3);
        this.animationNumber(156, this.$refs.projectRef);
        this.animationNumber(60, this.$refs.clientRef);
    },
};
</script>





