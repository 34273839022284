<template>
    <div id="Apply_main">
        <Header />
        <div class="ApplyDiv">
            <div class="Apply_Title">
                <h2>APPLY</h2>
                <p>BIMPeers와 함께 할 인재를 찾습니다. 지금 도전하세요!</p>
            </div>

            <div class="formDiv">
                <div class="form_Title">
                    <h2>웹 개발 엔지니어</h2>
                    <h3>소프트웨어개발 | 반응형 웹 | 인터페이스 | UI&UX 기획</h3>
                </div>

                <form action="#" accept-charset="utf-8" name="name" method="get">
                    <h3>이름</h3>
                    <fieldset>
                        <legend></legend>
                        지원자 이름을 입력해 주세요.
                    </fieldset>

                    <h3>전화번호</h3> 
                    <fieldset>
                        <legend></legend>
                        연락 가능한 전화번호를 입력해 주세요.
                    </fieldset>

                    <h3>이메일</h3>
                    <fieldset>
                        <legend></legend>
                        이메일 주소를 입력해 주세요.
                    </fieldset>

                    <h3>이력서 및 경력기술서</h3>
                    <fieldset>
                        파일 첨부하기
                    </fieldset>
                    <p>* 파일은 가급적 pdf 형식으로 올려주세요.<br>
                        노션 등 웹 형태의 지원서는 지원서에<br>웹 링크를 함께
                        첨부해 주시면 좋아요.</p>

                    <h3>포트폴리오</h3>
                    <fieldset>
                        <legend></legend>
                        파일 첨부하기
                    </fieldset>
                    <p>
                        * 포트폴리오는 최대 50MB까지 업로드 가능하며,<br>
                        웹 링크(노션, 구글 드라이브, 깃허브 등)으로 제출 시<br>pdf 문서 혹은
                        txt 파일에 URL을 첨부하여 제출해주세요.
                    </p>
                </form>

                <div class="ApplyBtnDiv">
                    <div class="apply">
                        <a href="#">제출하기</a>
                    </div>

                    <div class="cancel">
                        <a href="#">취소하기</a>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
    </div>

</template>

<style scoped> 
@import url('../../assets/css/apply.css');
</style>

<script>
import Header from "../../components/PageHeader.vue"
import Footer from "../../components/PageFooter.vue"
export default { 
    name:'App',
    components: {
        Header,
        Footer
    },

}
</script>
