<template>
    <div id="ci_main">
        <PageHeader />
        <div class="ci_Wrapper">
            <div class="ci_Container">
                <div class="ci_Tit">
                    <h2>CI 소개</h2>
                    <!-- <p>BIMPeers CI 소개</p> -->
                </div>
    
                <div class="CI_Content">
                    <button @click="onClickDownloadCI">CI 다운로드</button>
                    <div class="ci_intro">
                        <img src="../../assets//images/about/grid.png" alt="">
    
                        <div class="cl_Content">
                            <!-- <div class="cl_intro">
                            </div> -->
        
                            <div class="cl_intro">
                                <div class="cl_info">
                                    <div class="web_C">
                                        <strong>Main Color</strong>
                                        <p>#4d8fcc | R77 G 34 B204</p>
                                </div>
                            </div>

                                        
                            <div class="cl_info_sub">
                                <div class="web_C">
                                    <strong>Sub Color</strong>
                                    <p>#9d9fa1 | R157 G159 B161</p>
                                </div>
                                
                            </div>
                        </div>
                    </div>

                        <div class="font_info">
                            <div class="font_Container">
                                <div class="font_En">
                                    <h4 class="font_desc">Font Name - 돋움 Bold | 자간 0 | 장평 100%</h4>
                                </div>
                
        
                                <p class="ps">
                                    * 상호명은 상기 기재된 형식으로 반드시 기재하여야 한다.<br>
                            * 지정된 색상 이외의 색상은 사용을 금한다.<br>
                            * 임의대로 로고의 비율과 폰트의 비율 수정을 금한다.<br>
                                </p>
                            </div>
                            
                        </div>
                </div>
            </div>
        </div>
        </div>
        <PageFooter />
    </div>
</template>

<style scoped>
@import url('../../assets/css/ciInfo.css');
</style>

<script>
import PageHeader from '../../components/PageHeader.vue';
import PageFooter from '../../components/PageFooter.vue'
export default { 
    name:'App',
    components: {
        PageHeader,
        PageFooter
    },
    methods: {
        onClickDownloadCI() {
            // 파일 URL 설정
            const fileUrls = [
                require('../../assets/files/BIMPeers_Ci.png')
            ];

            fileUrls.forEach(url => {
            // AJAX 요청 생성
            const xhr = new XMLHttpRequest();
            xhr.open('GET', url, true);
            xhr.responseType = 'blob'; // Binary data로 설정

            xhr.onload = function() {
                // AJAX 요청이 성공적으로 완료된 경우
                if (this.status === 200) {
                    // 응답으로부터 Blob 객체 생성
                    const blob = this.response;

                    // Blob을 다운로드할 수 있는 URL로 변환
                    const blobUrl = window.URL.createObjectURL(blob);

                    // 링크 생성 및 다운로드
                    const link = document.createElement('a');
                    link.href = blobUrl;
                    link.setAttribute('download', ''); // 파일을 다운로드할 수 있도록 설정
                    document.body.appendChild(link);
                    link.click();

                    // 사용이 끝난 후에는 URL 해제
                    window.URL.revokeObjectURL(blobUrl);
                    document.body.removeChild(link);
                }
            };

            xhr.send();
            });
        }
    }

}
</script>
